import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import storage from "../utils/storage";

const routes = [
  {path: "/", redirect: storage.getItem('token')?"/own":'/login'},
  {
    path: "/login",
    name: "login",
    meta: {
      title:'惠捷智运',
    },
    component: () => import("../pages/Login.vue")
  },
  {
    path: "/moveInto",
    name: "moveInto",
    meta: {
      title:'惠捷智运',
    },
    component: () => import("../pages/MoveInto.vue")
  },
  {
    path: "/recommend",
    name: "recommend",
    meta: {
      title:'推荐',
    },
    component: () => import("../pages/Recommend.vue")
  },
  {
    path: "/register",
    name: "register",
    meta: {
      title:'企业信息',
    },
    component: () => import("../pages/Register.vue")
  },
  {
    path: "/registerSuccess",
    name: "registerSuccess",
    meta: {
      title:'入驻',
    },
    component: () => import("../pages/RegisterSuccess.vue")
  },
  {
    path: "/personal",
    name: "personal",
    meta: {
      title:'个人信息',
    },
    component: () => import("../pages/Personal.vue")
  },
  {
    path: "/own",
    name: "own",
    meta: {
      title:'我的',
    },
    component: () => import("../pages/Own.vue")
  },
  {
    path: "/auditRegister",
    name: "auditRegister",
    meta: {
      title:'入驻',
    },
    component: () => import("../pages/AuditRegister.vue")
  },
  {
    path: "/rewardList",
    name: "rewardList",
    meta: {
      title:'已发放奖励',
    },
    component: () => import("../pages/RewardList.vue")
  },
  {
    path: "/rewardPool",
    name: "rewardPool",
    meta: {
      title:'奖励池',
    },
    component: () => import("../pages/RewardPool.vue")
  },
  {
    path: "/myCard",
    name: "myCard",
    meta: {
      title:'我的卡',
    },
    component: () => import("../pages/MyCard.vue")
  },
  {
    path: "/addCard",
    name: "addCard",
    meta: {
      title:'添加银行卡',
      keepAlive: true
    },
    component: () => import("../pages/AddCard.vue")
  },
  {
    path: "/chooseCard",
    name: "chooseCard",
    meta: {
      title:'选择开户行',
    },
    component: () => import("../pages/ChooseCard.vue")
  },
  {
    path: "/withdraw",
    name: "withdraw",
    meta: {
      title:'余额提现',
    },
    component: () => import("../pages/Withdraw.vue")
  },
  {
    path: "/share",
    name: "share",
    meta: {
      title:'二维码',
    },
    component: () => import("../pages/Share.vue")
  },
  {
    path: "/poster",
    name: "poster",
    meta: {
      title:'画报分享',
    },
    component: () => import("../pages/Poster.vue")
  },
  {
    path: "/rule",
    name: "rule",
    meta: {
      title:'规则',
    },
    component: () => import("../pages/Rule.vue")
  },
  {
    path: "/withdrawAudit",
    name: "withdrawAudit",
    meta: {
      title:'提现详情',
    },
    component: () => import("../pages/WithdrawAudit.vue")
  },
  {
    path: "/records",
    name: "records",
    meta: {
      title:'交易记录',
    },
    component: () => import("../pages/Records.vue")
  },
  {
    path: "/recordsDetails",
    name: "recordsDetails",
    meta: {
      title:'交易详情',
    },
    component: () => import("../pages/RecordsDetails.vue")
  }
]
export const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes: routes,
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return {top: 0, left: 0}
    }
  }
})