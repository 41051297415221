<template>
    <div :class="state.userType">
        <router-view v-slot="{ Component }" v-wechat-title="state.userType==='shipperMini'?route.meta.title:'惠捷智运'">
            <keep-alive>
                <component :is="Component" v-if="route.meta.keepAlive"/>
            </keep-alive>
            <component :is="Component" v-if="!route.meta.keepAlive"/>
        </router-view>
    </div>
</template>

<script>
  import {getCurrentInstance, reactive} from 'vue'
  import {useRoute} from 'vue-router'
  import storage from "@/utils/storage";
  import {getUrl} from "@/utils/index";

  export default {
    name: 'App',
    setup() {
      const {proxy} = getCurrentInstance()
      const route = reactive(useRoute())
      if (getUrl(location).token) {
        storage.setItem('token', getUrl(location).token)
      }
      if (getUrl(location).code) {
        storage.setItem('userId', getUrl(location).code)
      }
      if (getUrl(location).from === 'carrier') {
        storage.setItem('userType', 'carrier')
      }
      if (getUrl(location).from === 'shipper') {
        storage.setItem('userType', 'shipper')
      }
      if (getUrl(location).from === 'shipperMini') {
        storage.setItem('userType', 'shipperMini')
      }
      const state = reactive({
        userType: storage.getItem('userType') || "",
      })
      return {
        state,
        route
      }
    }
  }
</script>
<style lang="scss" scoped>
    /deep/ {
        .gradientBg {
            .sticky {
                background: transparent !important;
            }

            background: linear-gradient(180deg, rgba(1, 155, 240, 0.8), rgba(106, 197, 246, 0.8)) !important;
        }

        .theme.sticky{
            background: $theme;
        }
        .van-overlay {
            z-index: 10;
        }

        .bgTheme {
            background: $theme !important;
        }

        .other {
            color: #FFFFFF !important;
        }

        .theme {
            color: $theme !important;
        }

        .container {
            .sticky.white {
                background: #ffffff !important;
                border-bottom: 0.01rem solid $border !important;

                .titleText {
                    color: #000000 !important;
                }

                .backBtn {
                    .van-icon {
                        color: #000000 !important;
                    }
                }

                .other {
                    color: #333333 !important;

                    &.theme {
                        color: $theme !important;
                    }
                }
            }
        }

    }

    .carrier {
        /deep/ {
            .gradientBg {
                .sticky {
                    background: transparent !important;
                }

                background: linear-gradient(0deg, #ABD5FD, #0084FF) !important;
            }

            .sticky {
                background: $carrierTheme !important;
                border-bottom: none !important;

                .titleText {
                    color: #FFFFFF !important;
                }

                .backBtn {
                    .van-icon {
                        color: #FFFFFF !important;
                    }
                }

                .other {
                    color: #FFFFFF !important;

                    &.theme {
                        color: $carrierTheme !important;
                    }
                }
            }

            .sticky.white {
                .other {
                    color: #FFFFFF !important;

                    &.theme {
                        color: $carrierTheme !important;
                    }
                }
            }

            .theme {
                color: $carrierTheme !important;

                &.van-icon {
                    color: $carrierTheme !important;
                }
            }

            .bgTheme {
                background: $carrierTheme !important;
            }
            .themeBorder{
                border-color: $carrierTheme!important;
            }
            .van-button--plain.van-button--primary {
                color: $carrierTheme !important;
                background-color: #FFFFFF !important;
                border: 1px solid $carrierTheme !important;
            }

            .van-button--primary {
                background-color: $carrierTheme !important;
                border: $carrierTheme !important;
            }
        }
    }

    .shipperMini,
    .shipper {
        /deep/ {
            .gradientBg {
                .sticky {
                    background: transparent !important;
                }

                background: linear-gradient(0deg, #B2EAE2, #14907E) !important;
            }

            .sticky {
                background: $shipperTheme !important;
                border-bottom: none !important;

                .titleText {
                    color: #FFFFFF !important;
                }

                .backBtn {
                    .van-icon {
                        color: #FFFFFF !important;
                    }
                }

                .other {
                    color: #FFFFFF !important;

                    &.theme {
                        color: $shipperTheme !important;
                    }
                }
            }

            .sticky.white {
                .other {
                    color: #FFFFFF !important;

                    &.theme {
                        color: $shipperTheme !important;
                    }
                }
            }

            .theme {
                color: $shipperTheme !important;

                &.van-icon {
                    color: $shipperTheme !important;
                }
            }

            .bgTheme {
                background: $shipperTheme !important;
            }

            .themeBorder{
                border-color: $shipperTheme!important;
            }

            .van-button--plain.van-button--primary {
                color: $shipperTheme !important;
                background-color: #FFFFFF !important;
                border: 1px solid $shipperTheme !important;
            }

            .van-button--primary {
                background-color: $shipperTheme !important;
                border: $shipperTheme !important;
            }
        }
    }

    /deep/{
        .shipperMini.container {
            width: 100%;
            display: table;
        }
    }

</style>
