import {createApp} from 'vue'
import App from './App.vue'
import {router} from './route'
import {Toast, Uploader, Icon, ImagePreview, Button, PullRefresh, List, Overlay} from 'vant';
import 'vant/lib/index.less';
import axios from "./utils/axios";
import api from "./api/index";
import mitt from 'mitt'
import dayjs from 'dayjs'
import "babel-polyfill";
// import VConsole from 'vconsole'
import VueWechatTitle from 'vue-wechat-title'
// const vConsole = new VConsole()
const app = createApp(App)
const emitter = mitt()
app.config.globalProperties.$toast = Toast;
app.config.globalProperties.$imagePreview = ImagePreview;
app.config.globalProperties.$request = axios;
app.config.globalProperties.$api = api;
app.config.globalProperties.$bus = emitter;
app.config.globalProperties.$dayJs = dayjs;
app.use(router)
app.use(Toast);
app.use(Icon);
app.use(Button);
app.use(ImagePreview);
app.use(Uploader);
app.use(PullRefresh);
app.use(List);
app.use(Overlay);
// app.use(vConsole);
app.use(VueWechatTitle);
app.directive('filterEmptyChar', {
  updated: function (el, {modifiers}, vnode) {
    try {
      if (!el.value) {
        return false;
      }
      el.value = el.value.replace(/\s/g, "");
      el.dispatchEvent(new Event(modifiers.lazy ? 'change' : 'input'))
    } catch (e) {

    }
  }
});
app.directive('filterOnlyNumber', {
  updated: function (el, {modifiers}, vnode) {
    try {
      if (!el.value) {
        return false;
      }
      el.value = el.value.replace(/\D/g, "");
      el.dispatchEvent(new Event(modifiers.lazy ? 'change' : 'input'))
    } catch (e) {

    }
  }
});
app.directive('filterDigit', {
  updated: function (el, {modifiers}, vnode) {
    try {
      if (!el.value) {
        return false;
      }
      el.value = el.value.replace(/[^(\d||/.)]/g, "");
      el.dispatchEvent(new Event(modifiers.lazy ? 'change' : 'input'))
    } catch (e) {

    }
  }
});
app.mount('#app')
