import Axios from 'axios'
import {Toast} from 'vant';
import storage from "./storage";

let axios = Axios.create({
  baseURL: '/api',
  timeout: '0',  //请求超时时间
  headers: {        //header传值，例如：Authorization
    'Content-Type': "application/json;charset=UTF-8",
    'jtiToken': storage.getItem('token')
  }
})
axios.interceptors.request.use(function (config) {
  if (storage.getItem('token')) {
    config.headers.jtiToken = storage.getItem('token');
  }
  if (config.method === 'get') {
    config.params = {...config.data};
  }
  //在发送请求之前做某事
  return config;
}, function (error) {
  //请求错误时做些事
  return Promise.reject(error);
});

//添加响应拦截器
axios.interceptors.response.use(function (response) {
  if (response.data.status.statusCode !== 0) {
    Toast({
      message: response.data.status.statusReason
    })
  }
  return {
    ...response.data,
    code: response.data.status.statusCode === 0,
    message: response.data.status.statusReason
  };
}, function (error) {
  // Toast({
  //   message: JSON.stringify(error.response),
  //   duration:100000
  // })
  switch (parseInt(error.response.status, 0)) {
    case 500:
      break;
    case 404:
      break;
    case 401:
      const userType = storage.getItem('userType')
      storage.clear()
      if (userType === 'shipper') {
        Logout.postMessage('')
        break
      }
      if (userType === 'carrier') {
        android.Logout()
        break
      }
      if (userType === 'shipperMini') {
        wx.miniProgram.reLaunch({
          url: '/pages/login/index'
        });
        break
      }
      window.location.href = './login'
      break;
    default:
      break
  }
  if (error.response) {
    return Promise.reject(error.response.data);
  } else {
    return Promise.reject(error);
  }
})
export default axios