import md5 from 'js-md5';

export const getUUID = () => {
  let d = new Date().getTime()
  if (window && window.performance && typeof window.performance.now === 'function') {
    d += performance.now()
  }
  return 'xxxxxxxx-xxxx-yxxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export function md5Crypto(password){
  return md5(password);
}
export function getUrl(url) {
  let search = url.search;
  let arr = search.slice(1).split("&");
  let obj = {};
  for (let i = 0; i < arr.length; i++) {
    obj[arr[i].split("=")[0]] = arr[i].split("=")[1];
  }
  return obj;
}