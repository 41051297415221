const api = {
  file: {
    getToken: '/thirdInterface/oss/recommendMobilePolicy'
  },
  getCode: 'oauth/recommend/recommendSendVerifyCode',
  login: '/oauth/recommend/login',
  addShipper: '/operationManagement/consignor/shipper/saveRecommendConsignor',
  getCenter: '/operationManagement/recommend/getByRecommendId',
  recommendConsignorList: '/operationManagement/recommend/recommendConsignorList',
  recommendRewardList: 'operationManagement/recommend/recommendRewardList',
  getConsignorById:'/operationManagement/recommend/getConsignorById',
  myReward:'/operationManagement/recommend/myRecommend',
  bindingCard:'/operationManagement/recommend/bindingCard',
  unbindingCard:'/operationManagement/recommend/unbindingCard',
  getWallet:'/operationManagement/recommendWallet/getWalletById',
  getCard:'/operationManagement/ocr/ocrRecognizeBankCard',
  withdrawal:'/operationManagement/recommend/withdrawal',
  getRecordList:'/operationManagement/recommendWallet/getList',
  getRecordDetails:'/operationManagement/recommendWallet/getDataById',
}
export default api